<template>
  <div class="text-center m-5">
    <div
      class="circle-loader"
      :class="{ 'load-complete': this.response.data.emailValidatedFlag }"
    >
      <div
        v-show="this.response.data.emailValidatedFlag"
        class="checkmark draw"
      ></div>
    </div>
    <div
      v-show="this.response.data.emailValidatedFlag"
      class="color-blue-grey-700"
    >
      Email Validated. You're the best!
    </div>
  </div>
</template>

<script>
export default {
  name: "emailvalidation",
  data() {
    return { response: "" };
  },
  methods: {
    validateEmail() {
      let url = process.env.VUE_APP_BASE_API_URL + "account/emailValidate";
      let code = this.$route.query.code;
      let that = this;
      this.axios
        .post(
          url,
          {
            code: code
          },
          { withCredentials: true }
        ) //eslint-disable-next-line
        .then(function(resp) {
          that.response = resp;
        });
    }
  },
  mounted() {
    this.validateEmail();
  }
};
</script>

<style scoped>
.circle-loader {
  margin-bottom: 3.5em;
  border: 3px solid rgba(0, 0, 0, 0.2);
  border-left-color: #5cb85c;
  animation: loader-spin 1.2s infinite linear;
  position: relative;
  display: inline-block;
  vertical-align: top;
  border-radius: 50%;
  width: 7em;
  height: 7em;
}
.load-complete {
  -webkit-animation: none;
  animation: none;
  border-color: #5cb85c;
  transition: border 500ms ease-out;
}
.checkmark {
}
.checkmark.draw:after {
  animation-duration: 800ms;
  animation-timing-function: ease;
  animation-name: checkmark;
  transform: scaleX(-1) rotate(135deg);
}
.checkmark:after {
  opacity: 1;
  height: 3.5em;
  width: 1.75em;
  transform-origin: left top;
  border-right: 3px solid #5cb85c;
  border-top: 3px solid #5cb85c;
  content: "";
  left: 1.75em;
  top: 3.5em;
  position: absolute;
}
@keyframes loader-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: 1.75em;
    opacity: 1;
  }
  40% {
    height: 3.5em;
    width: 1.75em;
    opacity: 1;
  }
  100% {
    height: 3.5em;
    width: 1.75em;
    opacity: 1;
  }
}
</style>
